import { Api } from '@/core/api.js'
import { db_timers, db_checks_to_sync } from '@/database'
import { Notification } from '@/core/notification.js'
import { v4 as uuidv4 } from 'uuid'
import i18n from '@/i18n'
const notification = new Notification()

const api = new Api()

const getDefaultState = () => {
    return {
        timers: null,
        checksToSync: [],
        interval: null
    }
}

// initial state
const state = getDefaultState()

const getters = {
    getTimers(state, rootState) {
        return state.timers
    },

    getTimerById: (state, rootState) => (id) => {
        return state.timers.find((timer) => timer.id === id)
    },
    getCategories(state, rootState) {
        return state.categories
    }
}

const actions = {
    loadTimers(context, params) {
        if (!fnCheckConnection()) {
            return loadTimersOffline(context)
        }
        // const fakeTimers = [
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz09',
        //         name: 'Limpieza de trapos',
        //         description: 'Meter trapos en la lavadora',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     },
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz10',
        //         name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     },
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz11',
        //         name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     }
        // ]

        return api
            .get('timer-op/timers')
            .then((response) => {
                const timersArr = Object.values(response.data)

                // formattear y settear los Timers:
                context.dispatch('formatTimers', timersArr)

                // context.dispatch('formatTimers', fakeTimers)

                // actualizar los timers (pending alarm, next alarm, isAvailable):
                context.dispatch('updateTimers').then(() => {
                    const timers = [...context.getters.getTimers]
                    // console.log('timers', timers)
                    context.dispatch('generateNotifications', timers)
                })

                // setear función para chequear timers y actualizarlos por segundo:
                context.dispatch('setupInterval')
            })
            .catch((error) => {
                console.error(error)
                // return loadTimersOffline(context)
            })
    },

    loadCategories(context, params) {
        return api
            .get('admin/timer-op/categories')
            .then((response) => {
                context.commit('setCategories', response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    },

    generateNotifications(context, timers) {
        return new Promise((resolve, reject) => {
            if (timers.length == 0) resolve()
            else {
                const timer = timers.pop()
                let notificationDate = timer.currentPendingAlarm && moment().isBefore(moment(timer.currentPendingAlarm)) ? timer.currentPendingAlarm : timer.nextAlarm
                // console.log("add Timer notification info:", timer, moment(notificationDate).format('YYYY-MM-DD HH:mm:ss'), timer.currentPendingAlarm, timer.nextAlarm)
                notification.addLocalNotification({
                    title: 'Pendiente de firmar: ' + timer.name,
                    description: timer.description,
                    text: '¡Es hora de hacerlo!',
                    group: i18n.t('tools.timer_title'),
                    ongoing: true,
                    trigger: { at: moment(notificationDate).toDate() },
                    data: { type: 'timerOp', timerId: timer.id, date: moment(notificationDate).format('YYYY-MM-DD HH:mm:ss') }
                })
                setTimeout(() => {
                    context.dispatch('generateNotifications', timers)
                }, 2000)
            }
        })
    },

    getLastTimesDone(context, params) {
        if (!fnCheckConnection()) {
            return loadTimersOffline(context)
        }

        const timersCopy = structuredClone(context.state.timers)

        const timersIds = timersCopy
            .map((timer) => {
                return timer.id
            })
            .join(',')

        function floatToTimeStamp(float, offsetDays = 0) {
            const today = moment().add(offsetDays, 'days').format('YYYY-MM-DD 00:00:00')
            const timeStamp = moment(today)
                .add(float * 60, 'minutes')
                .valueOf()
            return timeStamp
        }

        return api
            .post('timer-op/timers/checked', { timers: timersIds })
            .then((response) => {
                const lastCheckedTimers = Object.values(response.data)

                timersCopy.forEach((timer) => {
                    const checked = lastCheckedTimers.find((ct) => ct.id === timer.id)

                    if (checked && checked.last_done) {
                        const lastDoneTimeStamp = moment(checked.last_done, 'YYYY-MM-DD HH:mm:ss').valueOf()
                        timer.lastAlarmDone = lastDoneTimeStamp

                        // Verificar si el timer ya no está disponible
                        if (timer.currentPendingAlarm && timer.lastAlarmDone >= timer.currentPendingAlarm) {
                            timer.isAvailable = false
                        } else {
                            // Si hay una alarma pendiente más reciente que la última firma, el timer está disponible
                            timer.isAvailable = timer.currentPendingAlarm > timer.lastAlarmDone
                        }
                    } else {
                        timer.lastAlarmDone = null
                        timer.isAvailable = true
                    }
                })

                context.commit('setTimers', timersCopy)
                db_timers.clear().then(() => {
                    timersCopy.forEach((value) => {
                        db_timers.setItem(value.id, value)
                    })
                })
            })
            .catch((error) => {
                console.error(error)
                // return loadTimersOffline(context)
            })
    },

    formatTimers(context, params) {
        if (!Array.isArray(params)) {
            return
        }

        function floatToTimeStamp(float, offsetDays = 0) {
            const today = moment().add(offsetDays, 'days').format('YYYY-MM-DD 00:00:00')
            const timeStamp = moment(today)
                .add(float * 60, 'minutes')
                .valueOf()
            return timeStamp
        }

        const timers = Object.values(params)

        const formattedTimers = timers.map((timer) => {
            let formattedAlarms
            let nextAlarmText
            if (JSON.parse(timer.periodicity).typePeriodicity === 'daily') {
                formattedAlarms = timer.times.map((float) => {
                    const formattedTime = { float: float, timestamp: floatToTimeStamp(float) }
                    return formattedTime
                })
            } else {
                formattedAlarms = timer.times.map((float) => {
                    const periodicity = JSON.parse(timer.periodicity)
                    const time = moment(floatToTimeStamp(timer.times, 1)).format('hh:mm') // Hora de la alarma
                    const currentDate = moment().format('YYYY-MM-DD') // Fecha actual

                    const nextAlarm = getNextAlarmDate(periodicity.days, time, periodicity.typePeriodicity, currentDate)
                    nextAlarmText = nextAlarm.nextAlarmDateTime

                    const formattedTime = { float: float, timestamp: floatToTimeStamp(float, nextAlarm.daysRemaining) }
                    return formattedTime
                })
            }

            // Obtener los timestamps de mañana, y si no existen crearlos (para evitar contador en 00:00)
            let closestNextTimestamp = null
            let smallestDifference = Infinity
            const currentTimestamp = new Date().getTime()

            for (let i = 0; i < formattedAlarms.length; i++) {
                const timeDifference = formattedAlarms[i].timestamp - currentTimestamp
                if (timeDifference > 0 && timeDifference < smallestDifference) {
                    smallestDifference = timeDifference
                    closestNextTimestamp = formattedAlarms[i].timestamp
                }
            }

            if (closestNextTimestamp === null) {
                if (JSON.parse(timer.periodicity).typePeriodicity === 'daily') {
                    const formattedAlarmsTomorrow = timer.times.map((float) => {
                        return { float: float, timestamp: floatToTimeStamp(float, 1) }
                    })
                    formattedAlarms.push(...formattedAlarmsTomorrow)
                } else {
                    const periodicity = JSON.parse(timer.periodicity)
                    const time = moment(floatToTimeStamp(timer.times, 1)).format('hh:mm') // Hora de la alarma
                    const currentDate = moment().format('YYYY-MM-DD') // Fecha actual

                    const nextAlarm = getNextAlarmDate(periodicity.days, time, periodicity.typePeriodicity, currentDate)

                    nextAlarmText = nextAlarm.nextAlarmDateTime

                    const formattedAlarmsTomorrow = timer.times.map((float) => {
                        return { float: float, timestamp: floatToTimeStamp(float, nextAlarm.daysRemaining) }
                    })
                    formattedAlarms.push(...formattedAlarmsTomorrow)
                }
            }

            const formattedTimer = {
                ...timer,
                times: formattedAlarms,
                isAvailable: false,
                previousAlarm: null,
                currentPendingAlarm: null,
                nextAlarm: null,
                lastAlarmDone: null,
                nextAlarmText: nextAlarmText
            }
            return formattedTimer
        })

        // setear Timers con los times formateados:
        context.commit('setTimers', formattedTimers)
        db_timers.clear().then(() => {
            formattedTimers.forEach((value, i) => {
                db_timers.setItem(value.id, { ...value, i })
            })
        })
    },

    updateTimers(context, params) {
        const currentTimestamp = moment().valueOf()

        const updatedTimers = state.timers.map((timer) => {
            const periodicity = JSON.parse(timer.periodicity)
            const isWeeklyOrBiweekly = ['weekly', 'biweekly'].includes(periodicity.typePeriodicity)

            if (isWeeklyOrBiweekly) {
                // Para timers semanales y quincenales
                const time = moment(timer.times[0].timestamp).format('HH:mm')
                const currentDate = moment().format('YYYY-MM-DD')

                const previousAlarm = getPreviousAlarmDate(periodicity.days, time, periodicity.typePeriodicity, currentDate)
                timer.previousAlarm = moment(previousAlarm.previousAlarmDateTime).valueOf()

                const nextAlarm = getNextAlarmDate(periodicity.days, time, periodicity.typePeriodicity, currentDate)
                timer.nextAlarm = moment(nextAlarm.nextAlarmDateTime, 'MMM DD HH:mm').valueOf()

                const dateCreated = moment(timer.earliest_alarm_date).valueOf()

                if (!timer.lastAlarmDone && dateCreated >= timer.previousAlarm && dateCreated >= currentTimestamp && !timer.currentPendingAlarm) {
                    timer.isAvailable = false
                    timer.currentPendingAlarm = null
                    timer.nextAlarmText = nextAlarm.nextAlarmDateTime
                } else if (timer.lastAlarmDone < timer.previousAlarm) {
                    timer.isAvailable = true
                    timer.currentPendingAlarm = timer.previousAlarm
                    timer.nextAlarmText = previousAlarm.previousAlarmDateTimeText
                } else {
                    timer.isAvailable = false
                    timer.currentPendingAlarm = null
                    timer.nextAlarmText = nextAlarm.nextAlarmDateTime
                }
            } else {
                // Para timers diarios, mantener la lógica existente
                let closestPreviousAlarmTimestamp = null
                let smallestDifference = Infinity

                const timestamps = timer.times.map((time) => time.timestamp)

                for (let i = 0; i < timestamps.length; i++) {
                    let timeDifference = currentTimestamp - timestamps[i]

                    if (timeDifference >= 0 && timeDifference < smallestDifference) {
                        smallestDifference = timeDifference
                        closestPreviousAlarmTimestamp = timestamps[i]
                    }
                }

                timer.previousAlarm = closestPreviousAlarmTimestamp

                if (closestPreviousAlarmTimestamp !== null) {
                    timer.currentPendingAlarm = closestPreviousAlarmTimestamp
                } else {
                    timer.currentPendingAlarm = null
                }

                if (closestPreviousAlarmTimestamp <= currentTimestamp && closestPreviousAlarmTimestamp > timer.lastAlarmDone) {
                    timer.isAvailable = true
                } else {
                    timer.isAvailable = false
                    timer.currentPendingAlarm = null
                }

                // Calcular la próxima alarma
                let closestNextTimestamp = null
                smallestDifference = Infinity

                for (let i = 0; i < timestamps.length; i++) {
                    const timeDifference = timestamps[i] - currentTimestamp
                    if (timeDifference > 0 && timeDifference < smallestDifference) {
                        smallestDifference = timeDifference
                        closestNextTimestamp = timestamps[i]
                    }
                }

                if (closestNextTimestamp !== null) {
                    timer.nextAlarm = closestNextTimestamp
                } else {
                    timer.nextAlarm = timestamps[0]
                }
            }

            return timer
        })

        context.commit('setTimers', updatedTimers)
    },

    setupInterval(context, params) {
        if (state.interval) {
            clearInterval(state.interval)
        }

        const interval = setInterval(() => {
            context.dispatch('updateTimers')
        }, 1000)

        context.commit('setInterval', interval)
    },

    setAlarmAsDone(context, params) {
        let requireSync = !(fnCheckConnection() && context.rootGetters.getFastConnection) && context.rootGetters.getOffline ? true : undefined

        const { alarm, checkDateTimestamp } = params

        // update Timers view
        const timersCopy = structuredClone(state.timers)

        let updatedAlarm = null

        const updatedTimers = timersCopy.map((timer) => {
            if (timer.id === alarm.id) {
                const currentPendingAlarm = alarm.currentPendingAlarm

                updatedAlarm = {
                    ...timer,
                    lastAlarmDone: currentPendingAlarm,
                    isAvailable: false,
                    currentPendingAlarm: null
                }

                return updatedAlarm
            } else return timer
        })

        context.commit('setTimers', updatedTimers)
        db_timers.clear().then(() => {
            updatedTimers.forEach((value, i) => {
                db_timers.setItem(value.id, { ...value, i })
            })
        })
        const employeeId = context.rootGetters['loginUser/getLocalEmployee']
        const formattedCheckedAlarm = moment(updatedAlarm.lastAlarmDone).format('YYYY-MM-DD HH:mm:ss')
        const formattedBusinessDate = moment(updatedAlarm.lastAlarmDone).format('YYYY-MM-DD')
        const formattedCheckDate = moment(checkDateTimestamp).format('YYYY-MM-DD HH:mm:ss')

        const alarmData = {
            employee_id: employeeId,
            alarm_date: formattedCheckedAlarm,
            business_date: formattedBusinessDate,
            check_date: formattedCheckDate
        }

        if (requireSync) {
            let id = uuidv4()
            db_checks_to_sync.setItem(id, { ...alarmData, alarm_id: updatedAlarm.id, id }).then(() => {
                context.dispatch('getCountSyncItems', {}, { root: true })
            })
            return true
        }

        notification.removeLocalNotification({
            type: 'timerOp',
            timerId: updatedAlarm.id,
            date: formattedCheckedAlarm
        })

        // update in API
        return api
            .post(`timer-op/timers/${updatedAlarm.id}`, alarmData)
            .then((response) => {
                const timers = context.getters.getTimers
                let timersNotification = timers.filter((timer) => timer.id == updatedAlarm.id)
                context.dispatch('generateNotifications', timersNotification)
            })
            .catch((error) => {
                console.error(error)
            })
    },
    syncChecks(context, params) {
        let checks = []
        db_checks_to_sync
            .iterate(function (value) {
                checks.push(value)
            })
            .then(async function () {
                const groupedChecks = checks.reduce((acc, check) => {
                    acc[check.alarm_id] = acc[check.alarm_id] || []
                    acc[check.alarm_id].push(check)
                    return acc
                }, {})

                Object.values(groupedChecks).forEach((group) => {
                    group.sort((a, b) => new Date(a.alarm_date) - new Date(b.alarm_date))
                })

                const batches = []

                let batch = {}
                let batchCount = 0

                for (const [alarm_id, group] of Object.entries(groupedChecks)) {
                    while (group.length > 0) {
                        if (!batch[alarm_id]) {
                            batch[alarm_id] = []
                        }

                        const toAdd = group.splice(0, 10 - batchCount)
                        batch[alarm_id] = batch[alarm_id].concat(toAdd)
                        batchCount += toAdd.length

                        if (batchCount >= 10) {
                            batches.push(batch)
                            batch = {}
                            batchCount = 0
                        }
                    }
                }

                if (batchCount > 0) {
                    batches.push(batch)
                }

                for (const batch of batches) {
                    await api
                        .post(`timer-op/sync`, { timers: JSON.stringify(batch) })
                        .then((res) => {
                            if (res.status == true) {
                                const idsToDelete = Object.values(batch)
                                    .flat()
                                    .map((check) => check.id)
                                idsToDelete.forEach((id) => db_checks_to_sync.removeItem(id))
                                context.dispatch('getCountSyncItems', {}, { root: true })
                            }
                        })
                        .catch(function (error) {
                            logError(error)
                            return false
                        })
                }

                context.dispatch('getCountSyncItems', {}, { root: true })
            })
    },
    getCountSync(context) {
        return new Promise((resolve, reject) => {
            var count = 0
            db_checks_to_sync
                .iterate(function (value, key, iterationNumber) {
                    count++
                })
                .then(function () {
                    resolve(count)
                })
        })
    }
}

function getNextAlarmDate(days, time, frequency, currentDate = moment().format('YYYY-MM-DD')) {
    const now = moment(currentDate).startOf('day')
    const targetTime = moment(time, 'HH:mm')

    let nextAlarm = null

    // Recorremos hasta los próximos 7 días para encontrar el primer día de alarma
    for (let i = 0; i <= 7; i++) {
        const dayOffset = (now.day() + i) % 7 // `now.day()` devuelve el día de la semana (0 es lunes)
        if (days.includes((dayOffset + 6) % 7)) {
            // Ajusta para que 0 sea lunes, 6 sea domingo
            const potentialDate = now.clone().add(i, 'days').set({
                hour: targetTime.hour(),
                minute: targetTime.minute()
            })

            if (i === 0 && moment().isBefore(potentialDate)) {
                // Si es el mismo día y la hora aún no ha pasado
                nextAlarm = potentialDate
            } else if (i > 0) {
                nextAlarm = potentialDate
            }

            if (nextAlarm) break // Encontramos el siguiente día de la alarma, salimos del bucle
        }
    }

    // Si la alarma es hoy y ya pasó la hora, añade el intervalo correspondiente
    if (nextAlarm.isSameOrBefore(moment(), 'minute')) {
        if (frequency === 'biweekly') {
            nextAlarm = nextAlarm.add(14, 'days') // Añade 14 días si es quincenal
        } else {
            nextAlarm = nextAlarm.add(7, 'days') // Añade 7 días si es semanal
        }
    }

    const totalMinutes = nextAlarm.diff(moment(), 'minutes')
    const exactDays = totalMinutes / (24 * 60) // Convertir minutos a días
    const daysRemaining = Math.floor(exactDays)
    const hoursRemaining = Math.floor((exactDays - daysRemaining) * 24)

    return {
        nextAlarmDateTime: nextAlarm.format('MMM DD HH:mm'),
        daysRemaining: daysRemaining + hoursRemaining / 24, // Días en formato decimal
        interval: exactDays.toFixed(2) // Intervalo en formato decimal
    }
}

function getPreviousAlarmDate(days, time, frequency, currentDate = moment().format('YYYY-MM-DD')) {
    const now = moment(currentDate).startOf('day')
    const targetTime = moment(time, 'HH:mm')

    let previousAlarm = null

    // Recorremos los últimos 7 días para encontrar el último día de alarma
    for (let i = 0; i <= 7; i++) {
        const dayOffset = (now.day() - i + 7) % 7 // Calcula el día retrocediendo
        if (days.includes((dayOffset + 6) % 7)) {
            // Ajusta para que 0 sea lunes, 6 sea domingo
            const potentialDate = now.clone().subtract(i, 'days').set({
                hour: targetTime.hour(),
                minute: targetTime.minute()
            })

            if (i === 0 && moment().isAfter(potentialDate)) {
                // Si es el mismo día y la hora ya pasó
                previousAlarm = potentialDate
            } else if (i > 0) {
                previousAlarm = potentialDate
            }

            if (previousAlarm) break // Encontramos el día anterior de la alarma, salimos del bucle
        }
    }

    // Si la alarma es hoy y aún no ha pasado la hora, restamos el intervalo correspondiente
    if (previousAlarm.isSameOrAfter(moment(), 'minute')) {
        if (frequency === 'biweekly') {
            previousAlarm = previousAlarm.subtract(14, 'days') // Resta 14 días si es quincenal
        } else {
            previousAlarm = previousAlarm.subtract(7, 'days') // Resta 7 días si es semanal
        }
    }

    const totalMinutes = moment().diff(previousAlarm, 'minutes')
    const exactDays = totalMinutes / (24 * 60) // Convertir minutos a días
    const daysElapsed = Math.floor(exactDays)
    const hoursElapsed = Math.floor((exactDays - daysElapsed) * 24)

    return {
        previousAlarmDateTime: previousAlarm.format('YYYY-MM-DD HH:mm:ss'),
        previousAlarmDateTimeText: previousAlarm.format('MMM DD HH:mm')
    }
}

function loadTimersOffline(context) {
    let timers = []
    return db_timers
        .iterate(function (value, key, iterationNumber) {
            timers.push(value)
        })
        .then(function () {
            timers.sort((a, b) => a.i - b.i)
            context.commit('setTimers', timers)
            context.dispatch('updateTimers')
            context.dispatch('setupInterval')
        })
}

const mutations = {
    setTimers(state, payload) {
        state.timers = payload
    },
    setInterval(state, data) {
        state.interval = data
    },
    setChecksToSync(state, data) {
        state.checksToSync = data
    },
    setCategories(state, categories) {
        state.categories = categories
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
